// Core
import { useContext } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// Utils
import { perPageVariants } from '../../utils/perPageVariants';

// UI
import { Pagination } from 'material-design/components';
import { FormInput } from 'components/Form/FormInput';
import StatisticByTerritoriesBar from './StatisticByTerritoriesBar/StatisticByTerritoriesBar';

import EmptyData from '../../EmptyData/EmptyData';
import Skeleton from '../../Skeleton/Skeleton';

// Styles
import styles from './Columnar.module.css';

const ColumnarBar = ({
	chartRef,
	amountTitle,
	statisticData,
	setStatisticData,
	countries,
	startEndDates,
	mapTotalPages,
	changeField,
	errors,
	accountMap,
	setIsPaginationPressed,
	pageLoading,
	perPage,
	setPage,
	page,
}) => {
	const { lang } = useContext(LangContext);

	return (
		<div ref={chartRef} className={styles['statistics_bar-chart']}>
			<div className={styles.statistic__mainContentHeaderInfo}>
				<div className={styles.statistic__mainContentHeaderInfo_title}>
					<FormattedMessage id={'rod.statistic.chart-title'} />
				</div>
			</div>
			{pageLoading ? (
				<Skeleton />
			) : accountMap.length ? (
				<>
					<div className={styles.statistic__mainContentHeaderInfo_amount}>
						{amountTitle && <FormattedMessage id={`${amountTitle}`} />}
					</div>
					<StatisticByTerritoriesBar
						data={accountMap}
						limit={perPage}
						allCountries={countries}
						lang={lang}
						sort={statisticData.sort}
						handleSelectCountry={(country) => {
							statisticData.countries = [country];
							setPage(1);
							setStatisticData({ ...statisticData });
						}}
						dates={{
							startDate: startEndDates[0],
							endDate: startEndDates[1],
						}}
					/>
					<div className={styles['statistics_bar-chart__controls']}>
						{mapTotalPages > 10 && (
							<Pagination
								page={page}
								total={mapTotalPages}
								paginate={(pageNumber) => {
									setPage(pageNumber);
									setIsPaginationPressed(true);
								}}
								items_on_page={perPage}
							/>
						)}
						<div className={styles['statistics_bar-chart__controls-pages']}>
							<span>
								<FormattedHTMLMessage id={'rod.statistic.per-page'} />
							</span>
							<FormInput
								type={'MuiSelectCurrencies'}
								name={'per_page'}
								onChange={changeField}
								errors={errors}
								theme="grayscale"
								defaultValue={true}
								data={statisticData}
								label={''}
								items={perPageVariants}
							/>
						</div>
					</div>
				</>
			) : (
				<EmptyData typePage={'audience'} />
			)}
		</div>
	);
};

export default ColumnarBar;
